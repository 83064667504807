import { Home, Mydevices, Profile }   from './components'
import { Routes, Route }              from 'react-router-dom'
import { PersistLogin, RequireAuth }  from './components'

const ROLES = {
  'User': 2001
}

function App() {

  return (
      <div className="appContainer">
        <Routes >
          <Route path="/"           element={ <Home  /> } />
          {/* PROTECTED ROUTES */}
          {/* <Route element={ <PersistLogin /> } > */}
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/profile"    element={ <Profile  /> } />
            </Route >
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/mydevices"  element={ <Mydevices  /> } />
            </Route>
          {/* </Route> */}
        </Routes>
      </div>
  )
}

export default App
