import axios                    from 'axios'
import { useEffect, useState }  from 'react'
import useAuth                  from '../hooks/useAuth'

const navItems = [
    { display: "Home", path:"/"},
    { display: "My Profile", path:"/profile"},
    { display: "My Devices", path:"/mydevices"},
]

const Navbar = () => {
    /* const [toggleMenu, setToggleMenu] = useState(false) */
    const { setUser }    = useAuth()
    const [appCli, setAppCli]       = useState('')
    const [isLogged, setIsLogged]   = useState(localStorage.getItem("isLogged"))

    const getAppCli = async () => {
        try {
            const clidData = await axios.post(`${process.env.REACT_APP_SERVERURL}/getclientid`)
            setAppCli(clidData?.data?.appClientId)
            localStorage.setItem("appCli", clidData?.data?.appClientId)
        } catch (err) {
            if (!err?.response) {
                console.error('No Server Response')
            } else {
                console.error('GET CLIENTID FAILED ', err?.message)
            }
        }
    }

    const login = async () => {
        localStorage.setItem("getlogin", true)
        window.location.href = `${process.env.REACT_APP_AUTHURL}/ologin?cli=${appCli}`
    }
    
    const logout = () => {
        setUser({})
        setIsLogged(false)
        //localStorage.clear()
        localStorage.removeItem("user")
        localStorage.removeItem("appCli")
        localStorage.removeItem("isLogged")
        localStorage.removeItem("getlogin")
        window.location.reload()
    }
    
    useEffect(() => {
        getAppCli()
    }, [])

    return (
        <nav>
            <div className="logo">
                My Profile
            </div>
            <ul className="links">
                { navItems.map((item, index) => (
                    <li key={ index } className="ptext">
                        <a href={ item.path }>{ item.display }</a>
                    </li>
                ))}
            </ul>
            <ul className="auth">
                <li className="ptext">
                    { isLogged || isLogged === true ? 
                        <span onClick={ logout } >Logout</span>
                        :
                        <span onClick={ login } >Login</span>
                    }    
                </li>
            </ul>
        </nav>
    )
}

export default Navbar



/*

<span onClick={ login } >Login</span>
<a href={`${process.env.REACT_APP_AUTHURL}/ologin?cli=${appCli}`}>Login</a>

axios({ method: 'post', url: `${process.env.REACT_APP_SERVERURL}/getclientid` })
.then( resp => setAppCli(resp.data.appClientId) )
.catch( err => console.log('GET CLIENTID ERROR ', err))

axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVERURL}/getfirid`,
    data: {
        token: accessToken, appCli
    }
})
.then( resp => {
    console.log('RESPFIRID ', resp.data)
    setUser(JSON.stringify( resp.data ))
//localStorage.setItem("user", JSON.stringify( resp.data ))
})
.catch( err => console.log('GET CLIENTID ERROR ', err))

*/
