import axios                    from 'axios'
import { useEffect, useState }  from 'react'
/* import { useNavigate }          from 'react-router-dom' */
import { Footer, Navbar }       from '.'
import useAuth                  from '../hooks/useAuth'

const initialState = {
  email: '', displayName:"", country: '', currency: '', language: ''
}
const currencies = [
  { key: 'usd', name: 'USD' },
  { key: 'eur', name: 'EURO' },
  { key: 'huf', name: 'HUF' },
]
const langs = [
  { key: 'en', name: 'English' },
  { key: 'hu', name: 'Magyar' },
  { key: 'de', name: 'Deuche' }
]

const Profile = () => {
  /* const navigate                        = useNavigate() */
  /* const [error, setError]               = useState('') */
  const { user }                        = useAuth()
  const [isLoading, setIsLoading]       = useState(false)
  const [formData, setFormData]         = useState(initialState)
  const [userData, setUserData]         = useState(null)
  const [selectedC, setSelectedC]       = useState(null)
  const [selectedL, setSelectedL]       = useState(null)

  const handleChange = (e) => {
    setFormData( prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    /* const { email } = formData */
  }

  const userDataToClient = () => {
    setIsLoading(true)
    try {
      axios.post(`${process.env.REACT_APP_SERVERURL}/userdatatoclient`, {
        firId: user.firId, appCli: localStorage.getItem("appCli")
      })
      .then( res => {
        setUserData(res?.data)
        setSelectedC(res?.data?.favCountryCode ?? null)
        setSelectedL(res?.data?.favLangshort ?? null)
        formData.email    = res?.data.email
        formData.language = res?.data.favLang
        formData.country  = res?.data.favCountry
        formData.currency = res?.data.favCurrencie
        formData.displayName = res?.data.displayName
        console.log('FD ', res?.data)
      })
      .finally(() => setIsLoading(true))
    } catch (err) {
        setIsLoading(false)
        if (!err?.response) {
            console.error('No Server Response')
        } else {
            console.error('GET USERDATA FAILED ', err?.message)
        }
    }
  }
  
  const verifyMyPhone = () => {
    axios({ method: 'post', url: `/verifiphonenumber` })
    .then( resp => {
      const url = `${resp.data.dmsGetUrl}&tempid=${resp.data.tempid}&l=${resp.data.lang}`
      window.open(url, '_blank')
    })
    .catch( err => console.log('GET PHONES AND IDS ERROR ', err))
  }

  const changeMyPhone = () => {
    /* axios({ method: 'post', url: `/verifiphonenumber` })
    .then( resp => {
      const url = `${resp.data.dmsGetUrl}&tempid=${resp.data.tempid}&l=${resp.data.lang}`
      window.open(url, '_blank')
    })
    .catch( err => console.log('GET PHONES AND IDS ERROR ', err)) */
  }

  useEffect(() => {
    //  TODO  QUERY THE VALID DATA FROM DB AND SETUP THE FORMDATA
    setFormData(initialState)
    userDataToClient()
  }, [])
  //  TODO  GET THE COUNTRY TO THE LOCALESTRING
  const timeToDate = (t) => {
    const today = new Date(t)
    const date  =`${today.getFullYear()}. ${today.toLocaleString('default', { month: '2-digit' })}. ${addZero(today.getDate())}.`
    //const time  =`${addZero(today.getHours())} óra`
    return date //+ '. ' + time
  }
const addZero = (n) => { return (parseInt(n, 10) < 10 ? '0' : '') + n }
console.log('FORMDATA ', formData)
console.log('selectedC ', selectedC)
  return (
    <>
      <Navbar />
      <div className="container mt-5">
        {/* //* DISPLAY THE EDITABLE USER DATAS  */}
        <form onSubmit={ handleSubmit } >
          <div className="row mb-3">
          {/* //* E-MAIL AND DISPLAYNAME */}
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-12">
                  <label className='form-label'>e-Mail</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className='form-control'
                    placeholder='Write your e-mail address'
                    value={ userData != null ? userData.email : "" }
                    onChange={ handleChange }
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label className='form-label'>Display name</label>
                  <input
                    id="displayName"
                    name="displayName"
                    type="text"
                    className='form-control'
                    placeholder='Write your display name'
                    value={ userData != null ? userData.displayName : ""  }
                    onChange={ handleChange }
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <button 
                    className="btn btn-block w-100 countryBtn mt-3"
                  >
                     Change Favorit Country with support
                  </button>
                </div>
              </div>
            </div>
          {/* //* SELECT FAVORIT COUNTRI AND CURRENCI */}
            <div className="col-md-6">
              <div className="row mb-3">
                <div className="col-md-12">
                  <label className='form-label'>Favorit Currency { selectedC }</label>
                  <select
                    id="currency"
                    name="currency"
                    type="text"
                    className='form-control'
                    onChange={ handleChange }
                  >
                    <option disabled  >- Select One -</option>
                    { currencies && currencies.map((item, index) => (
                      <option 
                        key={ index } 
                        value={ item.key }
                        selected={item.key === selectedC}
                      >
                        { item.name }
                      </option>
                    ) )}
                  </select>
                </div>
                <div className="col-md-12 mt-3">
                  <label className='form-label'>Favorit Language</label>
                  <select
                    id="language"
                    name="language"
                    type="text"
                    className='form-control'
                    onChange={ handleChange }
                  >
                    <option disabled >- Select One -</option>
                    { langs.map((item, index) => (
                      <option 
                        key={ index } 
                        value={ item.key || "" }
                        selected={item.key === selectedL}
                      >
                        { item.name }
                      </option>
                    ) )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <button type='submit' className='btn btn-sm btn-warning px-3' >Mentés</button>
        </form>
        {/* //* DISPLAY NON-EDITABLE FIELDS  */}
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="card text-white bg-info">
              <div className="card-header">
                <h5 className="card-title">
                  Phones
                </h5>
                <h6 className="card-subtitle mb-2 text-white">
                  Wellcome
                    &nbsp;
                    <span className='text-dark fw-bold fs-5'>
                      { userData && userData.fullName }
                    </span> 
                    &nbsp;
                  your phones information cards
                </h6>
              </div>
              <div className="card-body">
                {/* PHONE COUNTRY CODE */}
                <p className="card-text mt-3 d-flex justify-content-between">
                  +{ userData && userData.phone }
                  <button
                    className='btn btn-sm btn-outline-danger px-3'
                    onClick={ changeMyPhone }
                  >
                    Change my phone
                  </button>
                  <button
                    className='btn btn-sm btn-outline-warning px-3'
                    onClick={ verifyMyPhone }
                  >
                    Verify my phone
                  </button>
                </p>
                <p className="card-text mt-3">{ userData && userData.phoneCountries }</p>
                <p className="card-text mt-3">{ userData && timeToDate(userData.phoneVerified) }</p>
                <p className="card-text mt-3">
                  { userData && timeToDate(userData.phoneValid) }
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card text-white bg-success">
              <div className="card-header">
                <h5 className="card-title">
                  Personal ID
                </h5>
                <h6 className="card-subtitle mb-2 text-white">
                  Wellcome
                    &nbsp;
                    <span className='text-dark fw-bold fs-5'>
                      { userData && userData.fullName }
                    </span> 
                    &nbsp;
                  your personal ID information card
                </h6>
              </div>
              <div className="card-body">
                <p className="card-text mt-3">{ userData && userData.idType }</p>
                <p className="card-text mt-3">{ userData && userData.numbers }</p>
                <p className="card-text mt-3">{ userData && userData.idCountries }</p>
                <p className="card-text mt-3">
                  { userData && timeToDate(userData.idValid) }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profile






/*
axios({ method: 'post', url: `${process.env.REACT_APP_SERVERURL}/userdatatoclient` })
.then( resp => {
  setUserData(resp.data)
  formData.email    = resp.data.email
  formData.language = resp.data.favLang
  formData.country  = resp.data.favCountry
  formData.currency = resp.data.favCurrencie
  formData.displayName = resp.data.displayName
})
.catch( err => console.log('GET USER DATA TO CLIENT ERROR ', err))


axios({ method: 'post', url: `${process.env.REACT_APP_SERVERURL}/getphonesandids` })
.then( resp => {
  setUserData(resp.data.phonesAndIds)
})
.catch( err => console.log('GET PHONES AND IDS ERROR ', err))


const getPhonesAndIds = async () => {
  try {
    const phoneData = await axios.post(`${process.env.REACT_APP_SERVERURL}/getphonesandids`, {
      firId: user.firId, appCli: localStorage.getItem("appCli")
    })
    setUserData(phoneData?.data?.phonesAndIds)
  } catch (err) {
      if (!err?.response) {
          console.error('No Server Response')
      } else {
          console.error('GET PHONES AND IDS ERROR ', err?.message)
      }
  }
}

*/


