import { Footer, Navbar }       from '.'
import axios                    from 'axios'
import { useEffect, useState }  from 'react'
import { bgImg }                from '../assets'
import useAuth                  from '../hooks/useAuth'
import { useSearchParams }      from "react-router-dom"
import { Card, Container, Row, Col }  from 'react-bootstrap'

const Home = () => {
  const { user }                = useAuth()
  const [firdata, setFirdata]   = useState(null)
  const [userData, setUserData] = useState(null)
  const [searchParams, setSearchParams]   = useSearchParams()
  const [isLogged, setIsLogged] = useState(localStorage.getItem("isLogged"))
  const accessToken                       = searchParams.get("acct")

  const getFirId = async () => {
    try {
        const firData = await axios.post(`${process.env.REACT_APP_SERVERURL}/getfirid`, {
            token: accessToken, appCli: localStorage.getItem("appCli")
        })
        setFirdata(firData)
        if (firData?.data && !firData?.data?.error) {
            if (firData?.data?.firId !== '') {
              //setUser(JSON.stringify( firData?.data ))
              localStorage.setItem("user", JSON.stringify(firData?.data))
              localStorage.setItem("isLogged", true)
              setIsLogged(true)
              localStorage.removeItem("getlogin")
              window.location.reload()
            } else {
              localStorage.setItem("isLogged", false)
              setIsLogged(false)
              localStorage.removeItem("getlogin")
            }
          }
        } catch (err) {
          if (!err?.response) {
            console.error('No Server Response')
            } else {
              console.error('GET FIRID FAILED ', err?.message);
          }
          localStorage.removeItem("getlogin")
      }
    }
  const getuserDataIfLogged = async () => {
    try {
      const cliData = await axios.post(`${process.env.REACT_APP_SERVERURL}/clihome`, {
        firId: user.firId, appCli: localStorage.getItem("appCli")
      })
      setUserData(cliData?.data?.promoCode)
    } catch (err) {
      if (!err?.response) {
          console.error('No Server Response')
      } else {
          console.error('GET USERDATA FAILED ', err?.message)
      }
    }
  }
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isLogged"))) {
      getuserDataIfLogged()
    } else {
      if (JSON.parse(localStorage.getItem("getlogin"))) {
        getFirId()
      }
    }
  }, [])
 
  return (
    <>
      <div className="backgroundimg">
        <img src={ bgImg } alt="bgImg" />
      </div>
      <Navbar />
      <Container className="mb-5" >
        <Row className="my-5">
          <Col md={ 12 } className="text-center mt-5">
            <h1>Üdvözlünk a Profil oldaladon <br />{ localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).displayName }</h1>
          </Col>
        </Row>
        <Row className="mt-2 mb-5">
          <Col md={ 12 } className='text-center promotionlink'>
            <a href={ `${process.env.REACT_APP_PROMOTION_CODE}/${userData && userData}` }>{ userData && userData }</a>
          </Col>
        </Row>
        <Row className="mb-5">
          <div className="gridContainer">
            <Card className="item1">
              <Card.Body className="">
                <Card.Title className="">Profile Application</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Wellcome your profile website</Card.Subtitle>
                <Card.Text className="">Some quick example text to build on the card title and make up the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="item2">
              <Card.Body className="">
                <Card.Title className="">Accepted App's</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Accepted applications</Card.Subtitle>
                <Card.Text className="">You can view the list of applications that you have permission to access.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="item3">
              <Card.Body className="">
                <Card.Title className="">Phone</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Phone validation</Card.Subtitle>
                <Card.Text className="">You can check if your phone number is verified and how long it is valid.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="item4">
              <Card.Body className="">
                <Card.Title className="">Personal ID</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Personal ID subtitle</Card.Subtitle>
                <Card.Text className="">You can check if your Personal ID is verified and how long it is valid.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="item5">
              <Card.Body className="">
                <Card.Title className="">ÁSZF</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Általános Szerződési Feltételek</Card.Subtitle>
                <Card.Text className="">On this page you can accept the General Terms and Conditions, which are essential for the use of different applications.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Home






/*


axios({ method: 'post', url: `${process.env.REACT_APP_SERVERURL}/clihome`,
proxy: {
    protocol: 'http',
    host: '127.0.0.1',
    port: 3360
} })
.then( resp => {
  console.log('RP ', resp.data)
  setUserData(resp.data)
})
.catch( err => console.log('CLI POST ERROR ', err))



fetch('/clihome')
.then((res) => console.log('RES ', res))
.then((data) => setUserData(data))

const data = await axios({method: 'post',url: `/clihome`})

axios({
  method: 'post',
  url: `/clihome`,
  data: JSON.stringify({message: 'POST body'}),
  headers: {'Content-Type': 'application/json' }
})
.then( resp => console.log('CLI RESP ', resp))
.catch( err => console.log('CLI POST ERROR'))


package.json
"proxy": "https://dev1.smartcryptonote.com:4200",
"homepage": ".",
*/