import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth"

const RequireAuth = ({ allowedRoles }) => {
    const { user }  = useAuth()
    const location  = useLocation()
    return ( user?.firId ? < Outlet /> : <Navigate to='/' state={{ from: location }} replace /> )
}

export default RequireAuth
