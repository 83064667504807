import { useContext, useDebugValue } from "react"
import AuthContext from "../context/AuthProvider"

const useAuth = () => {
    const { user } = useContext(AuthContext)
    useDebugValue(user, user => user?.firId ? "Logged In" : "Logged Out")
    return useContext(AuthContext)
}

export default useAuth
