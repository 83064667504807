import React            from 'react'
import App              from './App';
import ReactDOM         from 'react-dom'
import { BrowserRouter} from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'

import './sass/style.scss'

ReactDOM.render(
    <BrowserRouter>
        <AuthProvider>
            <App />
        </AuthProvider>
    </BrowserRouter>
    ,document.getElementById('root')
)
