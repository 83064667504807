import axios                    from 'axios'
import { useEffect, useState }  from 'react'
import { Footer, Navbar }       from '.'
import { BsTrash }              from 'react-icons/bs'
import useAuth                  from '../hooks/useAuth'
import { Card, Col, Container, ListGroup, Row }  from 'react-bootstrap'

const Mydevices = () => {
  const { user }              = useAuth()
  const [devices, setDevices] = useState([])
  
  /**
   * *  GET THE DEVICES FROM THE SCN KEY
   */
  const geMydevices = async () => {
    try {
      const deviceData = await axios.post(`${process.env.REACT_APP_SERVERURL}/getmydevices`, {
        firId: user.firId, appCli: localStorage.getItem("appCli")
      })
      setDevices(deviceData?.data?.devices)
    } catch (err) {
      if (!err?.response) {
          console.error('No Server Response')
      } else {
          console.error('GET DEVICESDATA FAILED ', err?.message)
      }
    }
  }
  
  /**
   * *  DELETE SELECTED DEVICE FROM DATABASE OF SCN KEY
   * @param { Object } e (event) 
   */
  const deleteDevice = async (e) => {  //  uid, devicesId
    e.preventDefault()
    console.log('CLICK ', e.target.dataset.id)
    const data = await axios.post(`${process.env.REACT_APP_SERVERURL}/delmydevice`, {
      deviceId: e.target.dataset.id, firId: user.firId, appCli: localStorage.getItem("appCli")
    })
    console.log(data)
    window.location.reload()
  }
  
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isLogged"))) {
      geMydevices()
    }
  }, [])

  return (
    <>
      <Navbar />
      <Container className="mt-5">
        <Row className="row">
          <Col md={12} className="text-center">
            <h2>My Devices page</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={4} className="">
            <Card className="text-white bg-info">
              <Card.Body className="card-body">
                <Card.Title className="">Devices list</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Wellcome your devices list page
                </Card.Subtitle>
                <Card.Text className="card-text mt-3">
                  You can delete your previously saved devices
                </Card.Text>
                <Card.Text className="card-text">
                  The device you want to delete will only be deleted from the list after you accept the confirmation email
                </Card.Text>
                <Card.Text className="card-text">
                  You can delete devices one by one
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8} className="">
            <ListGroup className="">
              <ListGroup.Item 
                variant='info'
                className="text-center fw-bold fs-4" 
              >
                Saved devices
              </ListGroup.Item>
              { devices.map((item, index) => (
                <ListGroup.Item key={index} 
                  className="fw-light"
                  variant={`${index % 2 ? "success" : "secondary" }`}
                  onClick={ deleteDevice }
                >
                  <Row className="row">
                    <Col md={10} className="">
                      Device type: { item.device_type } | 
                      Browser: { item.client_name } | 
                      OS name: { item.os_name } | 
                      OS verzion: { item.os_version }
                    </Col>
                    <Col md={2} className="trashicon text-center px-2" data-id={ item.id }>
                      <BsTrash data-id={ item.id } color="red"/>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Mydevices
